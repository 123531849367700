import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { TextFont } from "../../ui/TextFont";
import dayjs, { Dayjs } from "dayjs";
import { calendar } from "../../../utils/calendar";
import { Time } from "../components/Time";
import { TabletParticipants } from "../components/TabletParticipants";
import {
  IMeet,
  IParticipantsAttendanceOfDay,
  TStringUnknownTupleArray,
} from "@interfaces/interface";
import { useData } from "./useData";
import { EditTimerOptions } from "@components/ui/TimePicker";
import { Colors } from "../../../constants/colors";
import { RenderWithCondition } from "@components/ui/RenderWithCondition";
import { Activity } from "../components/Activity";
import { toFormatHHmm, toFormatISO } from "@utils/toFormatTime";
import { NotificationConfirm } from "@components/ui/NotificationConfirm";
import {
  TEXT_CLOSE_WINDOW,
  TEXT_IF_NOT_EXIST,
  TEXT_SET,
  TEXT_SET_COMMON_SLOT,
} from "../../../constants/constants";

export interface IStartData {
  data: IMeet;
  handleTime: (type: "startTime" | "endTime", arg: any) => void;
  startTime: string;
  endTime: string;
  handleData: (arg: IMeet) => void;
  emails: string;
}
interface IProps {
  close: () => void;
  startData: IStartData;
  isUserHasEditPermissions: boolean;
  receivedParams?: Record<string, string>;
  rootHandleData?: (
    name: string | TStringUnknownTupleArray,
    value: unknown
  ) => void;
}

export const ActivityUsers: FC<IProps> = ({
  close,
  rootHandleData,
  startData,
  receivedParams,
  isUserHasEditPermissions,
}) => {
  const [participantsList, setParticipantsList] = useState<
    IParticipantsAttendanceOfDay[]
  >(null);

  const [baseDate, setBaseData] = useState<IStartData>(startData);

  const {
    startTime,
    endTime,
    data,
    handleData,
    handleTime,
    formatData,
    isOpenCommonSlot,
    setOpenCommonSlot,
    pressCommonSlot,
    handleSetTimeFromCommonSlot,
    participantLight,
    setParticipantLight,
    meetingDurationMins,
    commonSlot,
  } = useData(baseDate);

  const [currentDate, setCurrentDate] = useState<Dayjs>(dayjs(startTime));
  const [isOpenEmptyData, setOpenEmptyData] = useState(false);
  const [isLoading, setLoading] = useState(true);

  const { host } = window.location;
  const isTodayCommonSlot = dayjs().isSame(dayjs(commonSlot?.startTime), "day");

  let baseURL: string;
  switch (true) {
    case host.includes("dev") || host.includes("localhost"):
      baseURL = "https://app.dt.dev.tap2v.com";
      // baseURL = "https://calendar.dt.dev.tap2v.com";
      break;
    case host.includes("test"):
      baseURL = "https://app.dt.test.tap2v.com";
      // baseURL = "https://calendar.dt.test.tap2v.com";
      break;
    default:
      baseURL = "https://app.dogmatime.ru";
  }

  useEffect(() => {
    if (!participantsList?.length && !isLoading) {
      setOpenEmptyData(true);
    }
  }, [participantsList?.length, isLoading]);

  useEffect(() => {
    if (currentDate) {
      setBaseData({
        ...startData,
        startTime: toFormatISO(currentDate),
        // endTime: toFormatISO(currentDate.add(1, "hour")),
        endTime: toFormatISO(currentDate.add(meetingDurationMins, "minutes")),
      });
    }
  }, [currentDate]);

  useEffect(() => {
    if (currentDate && receivedParams)
      getActivityParticipants({
        ...receivedParams,
        startTime: currentDate.format("YYYY-MM-DD").concat("T00:00:00"),
        endTime: currentDate.format("YYYY-MM-DD").concat("T23:00:00"),
      });
  }, [currentDate, receivedParams]);

  function getActivityParticipants(receivedParams) {
    const base64data = window.btoa("admin:xgt5rjVM");
    const headers = {
      Authorization: `Basic ${base64data}`,
      Accept: "application/json",
      "Content-Type": "application/json; charset=utf-8",
    };
    setLoading(true);
    fetch(
      `${baseURL}/api/e-staff-integration/basic/v1/meetings/attendance?startTime=${receivedParams.startTime}&endTime=${receivedParams.endTime}&emails=${receivedParams.emails}`,
      {
        method: "POST",
        headers: headers,
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setParticipantsList(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setLoading(false);
      });
  }
  const handleHourSlotPress = (hour: number) => {
    if (hour === 24 || !isUserHasEditPermissions) return;

    const selectedDateTime = dayjs(currentDate.format("YYYY-MM-DD")).set(
      "hour",
      hour
    );
    let calculatedEndTime = selectedDateTime.add(1, "hour");
    const updatedStartTime = toFormatISO(selectedDateTime);

    if (
      calculatedEndTime.isAfter(selectedDateTime, "day") &&
      meetingDurationMins > 55
    ) {
      calculatedEndTime = dayjs(selectedDateTime).endOf("day");
    }

    const updatedEndTime = toFormatISO(calculatedEndTime);

    handleTime("startTime", updatedStartTime);
    handleTime("endTime", updatedEndTime);
    setCurrentDate(dayjs(updatedStartTime));
  };

  const render = {
    commonSlot:
      commonSlot?.endTime && commonSlot?.startTime ? (
        <StSlotWrapDiv>
          <TextFont size={16} weight="700" type="bold">
            Ближайший общий слот
          </TextFont>
          <StButtonSlotBtn onClick={pressCommonSlot}>
            <TextFont size={16}>{`${dayjs(commonSlot?.startTime).format(
              "DD.MM.YY"
            )}, ${calendar
              .getWeekDayRu(dayjs(commonSlot?.startTime).day())
              ?.toLowerCase()} ${toFormatHHmm(
              dayjs(commonSlot?.startTime)
            )}`}</TextFont>
          </StButtonSlotBtn>
        </StSlotWrapDiv>
      ) : (
        <StTextSlotTFont size={16} type="bold">
          Общий слот не найден
        </StTextSlotTFont>
      ),
    activity: (
      <Activity
        currentDate={currentDate}
        setCurrentDate={setCurrentDate}
        participantLight={participantLight}
        participants={participantsList}
        time={{ startTime, endTime }}
        handleHourSlotPress={handleHourSlotPress}
      />
    ),
  };

  return (
    <StContentWrapDiv>
      <StDateWrapDiv>
        <StButtonDateBtn onClick={() => undefined}>
          <TextFont size={16}>
            {`${dayjs(currentDate).format("DD.MM.YY")}, ${calendar
              .getWeekDayRu(dayjs(currentDate).day())
              ?.toLowerCase()}`}
          </TextFont>
        </StButtonDateBtn>
        <StTimeWrapBtn>
          <Time
            value={startTime}
            handleTime={handleTime}
            type={EditTimerOptions.StartTime}
          />
          <Time
            value={endTime}
            handleTime={handleTime}
            type={EditTimerOptions.EndTime}
          />
        </StTimeWrapBtn>
      </StDateWrapDiv>

      <BlockParticipants>
        <div>
          <TabletParticipants
            participantLight={participantLight}
            data={participantsList}
            setParticipantLight={setParticipantLight}
            handleData={handleData}
            startTime={startTime}
            endTime={endTime}
          />
          <RenderWithCondition condition={isUserHasEditPermissions}>
            {render.commonSlot}
          </RenderWithCondition>
        </div>
        <div>{render.activity}</div>
      </BlockParticipants>

      <RenderWithCondition condition={isOpenCommonSlot}>
        <NotificationConfirm
          phraseOk={TEXT_SET}
          textTile={TEXT_SET_COMMON_SLOT}
          onOk={() => {
            handleSetTimeFromCommonSlot();
            !isTodayCommonSlot && setCurrentDate(dayjs(commonSlot?.startTime));
          }}
          onCancel={() => setOpenCommonSlot(false)}
          isOpen
        />
      </RenderWithCondition>

      <RenderWithCondition condition={isOpenEmptyData}>
        <NotificationConfirm
          textTile={TEXT_IF_NOT_EXIST}
          subTextTile={TEXT_CLOSE_WINDOW}
          onOk={() => undefined}
          onCancel={() => undefined}
          isOpen
          isHideButtons
          styleContent={styleConfirm}
        />
      </RenderWithCondition>
    </StContentWrapDiv>
  );
};

const StContentWrapDiv = styled.div`
  background-color: ${Colors.backgroundMain};
  margin-top: -20px;
  padding: 20px;
  max-width: 940px;
  max-height: 430px;
  box-sizing: border-box;
`;
const StDateWrapDiv = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${Colors.white};
  border-radius: 10px;
  padding: 10px;
  margin-top: 20px;
  height: 52px;
  align-items: center;
`;
const StButtonDateBtn = styled.button`
  background-color: ${Colors.backgroundMain};
  border-radius: 5px;
  padding: 3px 5px 3px 9px;
  justify-content: center;
  height: 40px;
`;
const StTimeWrapBtn = styled.button`
  display: flex;
  gap: 7px;
`;
const BlockParticipants = styled.div`
  display: grid;
  grid-template-columns: 40% 60%;
  grid-template-rows: calc(100vh - 150px);
  grid-gap: 20px;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 20px 0;
`;
const StSlotWrapDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  z-index: -1;
`;
const StButtonSlotBtn = styled.button`
  background-color: ${Colors.lightGrey};
  border-radius: 4px;
  padding: 4px 8px;
  border: 1px solid ${Colors.placeholder};
  &:hover {
    transition: 0.5s ease;
    border: 1px solid ${Colors.textAccent};
    background-color: ${Colors.textGrey200};
  }
`;
const StTextSlotTFont = styled(TextFont)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  z-index: -1;
`;

export const styleConfirm = {
  padding: "20px",
  backgroundColor: "#f4d5b8",
  borderWidth: "1px",
  borderColor: Colors.blue,
  borderStyle: "solid",
};
