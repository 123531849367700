import React, { useEffect, useMemo, useState } from "react";
import {
  ActivityUsers,
  styleConfirm,
} from "./components/pages/activityUsers/ActivityUsers";
import { IMeet } from "@interfaces/interface";
import { RenderWithCondition } from "@components/ui/RenderWithCondition";
import dayjs from "dayjs";
import { NotificationConfirm } from "@components/ui/NotificationConfirm";
import { TEXT_CLOSE_WINDOW, TEXT_IF_NOT_DATA } from "./constants/constants";
import { toFormatHHmmss, toFormatISO } from "@utils/toFormatTime";

function App() {
  const [receivedParams, setReceivedParams] = useState<Record<
    string,
    string
  > | null>(null);
  const [isOpenEmptyData, setOpenEmptyData] = useState(false);
  const isToday = dayjs().isSame(dayjs(receivedParams?.startTime), "day");
  const startTimeFirstRender = useMemo(() => {
    const startOfDay = receivedParams?.date?.concat("T09:00:00") ?? "";

    return isToday ? toFormatISO(dayjs().startOf("hour")) : startOfDay;
  }, [receivedParams?.startTime]);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const dateQuery = queryParams.get("date");
    const emails = queryParams.get("emails");
    //novaev@tap2visit.com,shchiklina_m@dogma.ru,test@dogma.ru

    if (!emails) {
      setOpenEmptyData(true);
      return;
    }

    setReceivedParams({
      date:
        dateQuery.length === 10 && dayjs(dateQuery).isValid()
          ? dateQuery
          : dayjs().format("YYYY-MM-DD"),
      emails: emails,
    });
  }, [window.location.search]);

  return (
    <div className="App">
      <RenderWithCondition condition={!!receivedParams}>
        <ActivityUsers
          close={() => {}}
          isUserHasEditPermissions={true}
          startData={{
            data: {} as IMeet,
            startTime: startTimeFirstRender ?? "",
            endTime: receivedParams?.date?.concat("T10:00:00") ?? "",
            handleData: () => undefined,
            handleTime: () => undefined,
            emails: receivedParams?.emails ?? "",
          }}
          receivedParams={receivedParams}
        />
      </RenderWithCondition>
      <RenderWithCondition condition={isOpenEmptyData}>
        <NotificationConfirm
          textTile={TEXT_IF_NOT_DATA}
          subTextTile={TEXT_CLOSE_WINDOW}
          onOk={() => undefined}
          onCancel={() => undefined}
          isOpen
          isHideButtons
          styleContent={styleConfirm}
        />
      </RenderWithCondition>
    </div>
  );
}

export default App;
