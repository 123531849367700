import React, { FC, useMemo } from "react";
import { RenderWithCondition } from "@components/ui/RenderWithCondition";
import { TextFont } from "@components/ui/TextFont";
import { Colors } from "../../../constants/colors";
import {
  IExternalUser,
  IParticipantsAttendanceOfDay,
} from "@interfaces/interface";
import FlatList from "flatlist-react/lib";
import styled from "styled-components";
import { UserAvatar } from "./avatarForms/UserAvatar";
import { LetterAvatar } from "./avatarForms/LetterAvatar";
import dayjs from "dayjs";
interface IProps {
  data: IParticipantsAttendanceOfDay[];
  setParticipantLight: (arg: string) => void;
  participantLight: string;
  startTime: string;
  endTime: string;
}

export const Participants: FC<IProps> = ({
  data,
  participantLight,
  setParticipantLight,
  startTime,
  endTime,
}) => {
  let index = 0;

  function isExternalUserType(
    user: Record<string, any>
  ): user is IExternalUser {
    const isNestedUserProp = Object.prototype.hasOwnProperty.call(user, "user");
    if ("isExternalUser" in user) {
      return user.isExternalUser;
    }
    if (isNestedUserProp && "isExternalUser" in user) {
      return user.user.isExternalUser;
    }
    return !(
      "avatarFileId" in (isNestedUserProp ? user.user : user) ||
      "workPosition" in user
    );
  }

  const renderItem = (item: IParticipantsAttendanceOfDay, i: number) => {
    const isExternalUser = isExternalUserType(item);
    const isBusy = data
      .filter((line) => line.user.id === item?.user?.id)[0]
      .meetings.filter((meet) => !(dayjs(startTime) >= dayjs(meet.endTime)))
      .filter((meet) => !(dayjs(endTime) <= dayjs(meet.startTime))).length;

    const letterAvatarStyle = {
      backgroundColor: Colors.lightGrey,
      width: 32,
      height: 32,
    };
    const userAvatarStyle = {
      width: 32,
      height: 32,
      borderRadius: 32,
      marginRight: 8,
      border: `2px solid ${isBusy ? Colors.red : Colors.green}`,
    };

    return (
      <StButtonItemBtn
        key={item?.user?.id}
        isPointer={!isExternalUser}
        isActive={participantLight === item?.user?.id}
        onClick={() => !isExternalUser && setParticipantLight(item?.user?.id)}
      >
        <StContentItemDiv>
          <StBlockAvatarDiv>
            <StTextOrdinalTFont size={10} color={Colors.placeholder}>
              {i + 1}
            </StTextOrdinalTFont>
            <StAvatarWrapDiv>
              <RenderWithCondition condition={isExternalUser}>
                <LetterAvatar
                  userName={
                    isExternalUser
                      ? item?.firstName && item?.firstName[0]?.toUpperCase()
                      : ""
                  }
                  lastName={
                    isExternalUser
                      ? item?.lastName && item?.lastName[0]?.toUpperCase()
                      : ""
                  }
                  style={letterAvatarStyle}
                  colorText={Colors.white}
                />
              </RenderWithCondition>
              <RenderWithCondition condition={!isExternalUser}>
                <UserAvatar avatarFileId={null} style={userAvatarStyle} />
              </RenderWithCondition>
            </StAvatarWrapDiv>
          </StBlockAvatarDiv>
          <BlockText>
            <StTextUserNameTFont type="bold" size={16} weight="700">
              {item?.user?.lastName ?? ""} {item?.user?.firstName ?? ""}
            </StTextUserNameTFont>
            <StTextWorkPositionTFont size={14} color={Colors.placeholder}>
              {isExternalUser ? item?.email : item?.user?.workPosition ?? ""}
            </StTextWorkPositionTFont>
          </BlockText>
        </StContentItemDiv>
      </StButtonItemBtn>
    );
  };

  return (
    <>
      <StBlockHeaderDiv>
        <TextFont size={18} weight="700" type="bold">
          Участники встречи
        </TextFont>
      </StBlockHeaderDiv>

      <StListWrapDiv>
        <FlatList
          //@ts-ignore
          list={data}
          renderItem={(item: IParticipantsAttendanceOfDay) =>
            renderItem(item, index++)
          }
          renderWhenEmpty={() => null}
        />
      </StListWrapDiv>
    </>
  );
};

const StBlockHeaderDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 54px;
`;
const StListWrapDiv = styled.div`
  margin-top: 15px;
  gap: 8px;
`;
const StButtonItemBtn = styled.button<{
  isPointer: boolean;
  isActive?: boolean;
}>`
  display: flex;
  justify-content: space-between;
  padding-left: 8px;
  width: 96%;
  height: 55px;
  border-radius: 5px;
  padding-top: 0;
  padding-bottom: 0;
  background-color: ${Colors.white};
  ${({ isPointer }) => !isPointer && "cursor: default"};
  box-sizing: content-box;
  border: 1px solid transparent;
  border-bottom: 1px solid ${Colors.lightGrey};
  ${({ isActive }) =>
    isActive &&
    `
  background-color: ${Colors.green150};
  border:1px solid ${Colors.grey}`};
`;

const StContentItemDiv = styled.div`
  display: flex;
  flex: 8;
  height: 100%;
  width: 95%;
`;
const StBlockAvatarDiv = styled.div`
  display: flex;
`;
const StAvatarWrapDiv = styled.div`
  display: flex;
  position: relative;
  align-items: center;
`;
const BlockText = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
`;
const StTextOrdinalTFont = styled(TextFont)`
  align-self: flex-end;
  width: 9px;
  word-break: normal;
`;

const StTextUserNameTFont = styled(TextFont)<{ isLineThrough?: boolean }>`
  margin-bottom: 0;
  line-height: 24px;
  height: 24px;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  white-space: nowrap;
  ${({ isLineThrough }) => isLineThrough && "text-decoration: line-through;"}
`;

const StTextWorkPositionTFont = styled(TextFont)`
  line-height: 24px;
  height: 24px;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  white-space: nowrap;
`;
